import React, { useEffect, useState } from "react";
import './Home.css'
import Card from "./Card";
import Sidebar from "./Sidebar";

const sections = [
  { href: "#overview", title: "Overview" },
  { href: "#enrollment", title: "Enrollment" },
  { href: "#account", title: "Account Overview" },
  { href: "#setup", title: "Initial Setup" },
  { href: "#communication", title: "Communication" },
  { href: "#sdk", title: " Parklio SDK" },
  { href: "#code-examples", title: "Examples" }
];

const Home = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/data/doc.html') // Assuming the file is named 'my-html-file.html'
      .then(response => response.text())
      .then(data => {
        setHtmlContent(data);
      });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const currentId = entry.target.getAttribute('id');
          window.history.scrollRestoration = 'manual';
          window.history.replaceState({}, '', `#${currentId}`)
        }
      });
    }, { threshold: 0.5 });

    document.querySelectorAll('.section').forEach(section => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div className="home-container">
    <Sidebar links={sections}>
      <div className="home-view">
        <Card>
          <div className="section" id="overview">
            <h1>1. Overview</h1>
            <p>The Parklio API empowers clients to seamlessly incorporate the Parklio Ecosystem into their
              applications, unlocking a variety of innovative features provided by the Parklio API. This integration
              enables clients to harness functionalities such as remote control, real-time parking occupancy
              monitoring, license plate recognition (LPR) event tracking, access control, digital key sharing, and
              efficient parking management.</p>
            <p>
              To facilitate this integration, Parklio Gateways serve as the bridge for API communication. Additionally,
              the setup, maintenance, and operation of Parklio products within parking lots require the use of the
              Parklio Connect App.
            </p>
            <p>
              For clients who prefer a more direct approach, the Parklio SDK (available for both Android and iOS)
              offers an alternative. It allows for direct control of our products without the need for API remote
              control calls, making mobile-to-Bluetooth interactions even more accessible and efficient. This flexibility
              ensures that clients can choose the integration method that best suits their needs, offering a tailored
              experience for their users. This setup is ideal for clients that need to operate in offline environments or
              environments with low connectivity, utilizing the benefits of offline Bluetooth communication.
            </p>
          </div>
          <div className="section" id="enrollment">
            <h1>2. Enrollment</h1>
            <p>To start using the Parklio API, contact our representatives at <a href="mailto:recipient@example.com">info@parklio.com</a> to acquire an Parklio API
              Account.</p>
          </div>
          <div className="section" id="account">
            <h1>3. Account Overview</h1>
            <p>The Parklio API Account contains all the pieces of the Parklio Cloud to provide a full parking
              management solution for your applications. Figure 1 shows the complete architecture of the Parklio
              Account. It consists of four main components:</p>
            <ul>
              <li>Account Users</li>
              <li>Lots</li>
              <li>Products</li>
              <li>Gateways</li>
            </ul>
            <img alt="Parklio Account" src="/pictures/data_overview.jpg" />
            <h2>3.1. Account Users</h2>

            <p>Account users are users that belong to your Parklio Account. They are responsible for everyday Parking
              administration and maintenance, lot creating, zone creation, product management, key sharing. They
              are identified by their unique email.</p>
            <p>When you register your Parklio API Account, the email you provide will be used to create the default
              Account User and you will receive an email invite to create a password for it.</p>
            <p>The Account Users differ in roles:</p>
            <ul>
              <li>Super Admin</li>
              <li>Admin</li>
              <li>Operator</li>
            </ul>
            <h3>3.1.1. Account Roles</h3>
            <p><b>Super Admin</b> has full access rights to the whole Account and Account Security, they can assign new
              users, remove other users, assign roles, create lots, create zones, add products and share product keys.</p>
            <p><b>Admin</b> have full access to lots that have been assigned to them. Admins can create zones, add products
              and share product keys, change lot work hours and access times. They are assigned to Lots by Super
              Admins.</p>
            <p><b>Operators</b> have full access to products in a lot/zone and can add new products and share product keys,
              they are assigned to Lots by Admins/Super Admins.</p>
            <p>This granularity allows client applications to form a structure with them as the Super Admin, their
              clients as admins who have free reign over their parking lot and operators who would maintain their
              assigned lots.</p>
            <h3>3.1.2. Lot Overview</h3>
            <p>A Lot represents single or multiple Parking locations on which you want to integrate Parklio Cloud
              solutions. When created, lots have two default zones created in them. The default zone and the
              handicap zone. Zones are a granulation in the lot that allow splitting the parking lot into smaller more
              fine tunable entities.</p>
            <p>Example usage would be an Airport Parking Lot which is split into different zones based on the pricing,
              with more expensive and less expensive zones.</p>
            <p>Parklio products are assigned to Parking Zones, while the Parklio Gateways are assigned to Parking Lots
              as show in Figure 1.</p>
            <h3>3.1.3 Products</h3>
            <p>Parklio Products represent any hardware product that we use in our Parklio Cloud system. This includes
              Parklio Barriers, Gates, Chains, Bollards, LPR and other. They are assigned by the account user to zones
              within a parking lot. They provide all the functionality needed to manage your parking lot and provide
              access control to your parking lots and zones.</p>
            <p>Parklio Products use Bluetooth to communicate with connected devices (Smartphones, Gateways).
              During the initial setup of the Parklio Products using the Parklio Connect App, master keys are
              exchanged with the device and the Parklio API. All future operation keys are generated from the master
              key. This ensures that the devices can operate in locations with no network connection using Bluetooth
              communication.</p>
            <h3>3.1.4 Parklio Gateways</h3>
            <p>The Parklio Gateways provide on site connectivity with the Parklio Products. They are a SoC with
              Bluetooth, Ethernet and GSM capabilities.</p>
            <p>They are crucial for API to Product communication. Since the Parklio Products use Bluetooth as a means
              of communication, the Parklio Gateways bridge the gap by providing two way communication between
              the API and Parklio Products.</p>
            <p>They are assigned by the account users to parking lots.</p>
          </div>
          <div className="section" id="setup">
            <h1>4. Initial Setup</h1>
            <p>Before using the Parklio API, the baisc testing enviorment needs to be setup. Since we are using
              hardware devices, they need to be setup in a Parklio account. The only way to assign products and
              gateways to the Parklio Account is by using the Parklio Connect app.</p>
            <p>Use the provided PMS/API login credentials to login into the Parklio Connect Application. While in the
              App, navigate to the lots tab and select the default lot and default zone. Add one of the Parklio
              Products as shown in the<a href="https://parklio.com/assets/support/barrier/Parklio-barrier-usermanual.pdf"> Parklio Barrier User Manual</a>.</p>
            <p>After we have successfully added a product to the zone, it is time to add a Parklio Gateway by moving
              back to the lot screen. Make sure to follow all the instructions in the <a href="https://parklio.com/assets/support/gateway/Parklio-Gateway-UM.pdf">Parklio Gateway Manual</a> and
              ensure a proper connection is present. Before clicking the add button in the lot screen, ensure that the
              gateway is in pairing mode by doing a short press of the Gateway power button. The Gateway LEDs
              should starting blinking. Now you can press the add button and link the Gateway to this lot.</p>
            <p>This completes the initial setup needed to test the basic Parklio API functionality.</p>
          </div>
          <div className="section" id="communication">
            <h1>5. API Communication</h1>
            <p>Communication with the Parklio API is established using HTTP requests and live data is received using
              Web Sockets using the SocketIO v.4.4.0 library. <a href="/api" >API endpoints</a>, <a href="/ws">web socket events</a> and response
              examples are documented in the API Documentation.</p>
            <h2>5.1. Authentication</h2>
            <p>The Parklio API uses API keys to authenticate requests. After logging and authenticating with the user token on the <a href="api#/AUTH/AuthController_login"> Account User Login</a></p>
            <p>Once you logged in you can acquire an API Key from the<a href="api#/API TOKENS/ApiTokenController_create"> API Tokens endpoint</a></p>
            <p>Your API keys can be used to make all request related to your account, so be sure to keep them secure! Do not share your secret API keys
              in publicly accessible areas such as GitHub, client-side code, and so forth. They do not expire.</p>
            <h2>5.2. Product State</h2>
            <p>Every Parklio Product, advertises its state using BLE. Depending on the product type, state can contain
              various product information. Example state shared by all devices describes if the product is
              open/closed, if the time is set or if the master key is set. Extra information is based on the product type,
              for example the Parklio Barrier can contain info if a car is on top of it, if the sensor is dirty and so on.</p>
            <p>When a product state changes, for example battery goes from 85% to 84% on a Parklio barrier, the new
              barrier advertisement is received by the Parklio Gateway and sent to the API for authentication. Once
              the advertisement is authenticated, the API updates the Product state and triggers a lot_change event
              on the WebSockets to notify any connected client as documented in <a href="/ws#lot-change">Lot Change Event</a>.</p>
            <h3>5.2.1. Triggering a Product State Change</h3>
            <p>Clients can change the product state with the API by calling the change product state endpoint as
              documented in the <a href="/api#/PRODUCTS/post_v2_products__id__state">Change Product State Endpoint</a>.</p>
            <p>When called, the API calculates the best Gateway for the job based on the strength of the BLE signal,
              resource availability and load. If the API manages to find a suitable Gateway it responds with an Emit
              Successful message and event_id. This indicates that the command was sent asynchronous to the
              Gateway and upon completion will trigger a lot_change event on the WS with the accompanying
              event_id, informing the Client of a successful command completion. The complete flow is shown in
              Figure 2. If the API does not manage to complete the operation successfully (example a Barrier jams while
              lowering) the API will fire a gateway_error event as shown in <a href="/ws#gateway-error">Gateway Error Event</a>.</p>
            <img src="/pictures/client_server.jpg" alt="Change Product State Flow" />
          </div>
          <div className="section" id="detection-integration">
            <h1>6. Parking Detection Integration</h1>
            <p>Parklio enables users to track the occupancy of parking places on the owners lot. Parklio does this by
              utilizing cameras and AI computation devices installed on your parking lot. Since every parking lot is
              unique contact the Parklio Team for a quotation and support with integrating the system on your lot.</p>
            <p>For the best solution and fastest response, send detailed pictures and parking blueprints to our team.
              Based on the blueprint, pictures and information the Parklio team will give you a proposition on how to
              achieve the best coverage and detection on your parking lot.</p>
            <p>The system can also use <b>existing</b> IP cameras if your lot under surveillance already, further lowering
              costs and installation times.</p>
            <p>Once the hardware system has been installed our technicians will generate the parking place mappings
              and data inside a lot tied to your account. You will be able to receive real time data on parking place
              occupancy, parking detection status and camera frames.</p>
            <p>The whole setup of the Parking Detection is shown in Figure 3.</p>
            <img src="pictures/parking_detection.jpg" alt="Parking Detection" />
            <p>
              The users can query the API for real time parking place occupancy state by using the <a href="/api#/PARKING-PLACES/get_v2_parking_places">List All Parking
                Places Endpoint</a>. To receive real time websocket information the users can use the <a href="/ws#parking-place-change">Parking Place State
                  Change Event</a>.
            </p>
            <p>
              In case a problem with the Parking Detection Gateway occurs, an <a href="/ws#gateway-status">Parking Detection Gateway State
                Change Event</a> will be sent indicating that the parking lot detection is inoperable until fixed. The last
              known status of the parking places and camera frames will be shown.
            </p>
          </div>
          <div className="section" id="sdk">
            <h1>7. Parklio SDK</h1>
            <p>The Parklio SDK is a powerful resource comprising Android and iOS libraries, specifically designed for
              clients seeking seamless mobile phone integration. This option offers a distinct advantage compared to
              using the Parklio API in conjunction with Parklio Gateways, as it facilitates direct Bluetooth
              communication between the client's mobile application and Parklio Products.</p>
            <p>The initial setup of these products still relies on the Parklio Connect App, which facilitates the exchange
              of Master Keys between the Parklio API and the Parklio product. Subsequent communication with the
              products primarily revolves around the use of Operation Tokens to execute various operations and
              commands.</p>
            <p>Operation Tokens are designed to function efficiently in offline environments by employing a
              proprietary Bluetooth protocol for device-to-product communication. Each Operation Token comprises
              a command that can be executed on the device, along with a predefined time period during which it
              remains valid.</p>
            <p>It's worth noting that once Operation Tokens are generated, they are immutable and cannot be altered
              or modified. Any attempts to tamper with these tokens will render them inoperable. In cases where the
              activity period of a token needs to be extended, the solution lies in creating a completely new token
              with the desired time period, ensuring a secure and reliable operation of the system.</p>
            <p>To acquire a valid Operation Token the <a href="/api#/OPERATION-TOKENS/post_v2_operation_tokens">Create Operation Token Endpoint</a> can be used. This endpoint
              generate tokens for opening, closing and status requesting which enables clients to do all the actions
              necessary for successful product operation.</p>
            <p>Additionally the Parklio Library uses Bluetooth to scan for realtime advertisement of devices in
              proximity. This advertisement contains data on the state of the devices such ass open/close state,
              battery value, car on top, jammed, errors and similar. The status token can be used to refresh this data.</p>
            <img src="/pictures/sdk.jpg" alt="Parklio SDK Integration" />
          </div>
          <div className="section" id="code-examples">
            <h1>8. Code Examples</h1>
            <p>We provide a simple Parklio API Demo application implemented in NodeJS</p>
            <a href="https://gitlab.com/parklio-gitlab/parklio-api-demo" alt="Nodejs example">NodeJS</a>
            <div style={{ height: '100px' }}></div>

          </div>
        </Card>
      </div>
    </Sidebar >
  </div >
}

export default Home;